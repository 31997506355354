(function () {
  'use strict';

  /* @ngdoc object
   * @name bioscann
   * @description
   *
   */
  angular
    .module('bioscann', [
      'ngAria',
      'ngSanitize',
      'ui.router',
      'ngCookies',
      'ngMessages',
      'ngResource',
      'ngMaterial',
      'md.data.table',
      'textAngular',
      'ui.tree',
      'nvd3',
      'components',
      'home',
      'dashboard',
      'viewResource',
      'about',
      'invitation',
      'module',
      'ngActionCable',
      'angulartics',
      'angulartics.google.analytics',
      'colorpicker.module'
    ]);
}());
